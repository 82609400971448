.login-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -30%;
  z-index: 0;
}
.login-page {
  width: 100%;
  height: 100%;
  // background: url('../../../public/20240809-155615.jpg') no-repeat 0 220% / 100%;
  background-color: #02050a;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-title {
    color: #fff;
    margin-bottom: 10px;
  }
  .login-form {
    min-width: 500px;
    margin: auto;
    padding: 20px;
    border: 1px solid #183a5c;
    border-radius: 4px;
    background-color: #0e1c2a;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    .login-title {
      font-size: 1.5rem;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
    .ant-form-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-input {
      height: 40px;
      border-radius: 4px;
    }

    .ant-btn {
      width: 100%;
      height: 40px;
      border-radius: 4px;
    }

    .ant-message {
      .ant-message-notice-content {
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      }
    }
  }
}
.login-footer {
  width: 100%;
  height: 7%;
  background-color: #272727;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  padding: 0.5% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .login-footer-scopeOfApplication {
    color: #f80000;
  }
  .login-footer-text {
    color: #fafafa;
    text-decoration: none;
  }
}
