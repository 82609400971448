.resouseLayout {
  height: 100%;
  .lay-head {
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 30px;
    }
    span {
      color: #000000;
      font-size: 20px;
      font-weight: 650;
      margin-left: 15px;
    }
    .head-user {
      position: absolute;
      right: 2%;
      .management-btn {
        cursor: pointer;
        margin-right: 5px;
        &:hover {
          color: #404040;
        }
      }
      .name {
        margin-left: 5px;
      }
      .logout-box {
        cursor: pointer;
        .logout-icon {
          position: relative;
          top: 2px;
        }
        .logout {
          margin-left: 5px;
        }
      }
      &:hover {
        .logout {
          color: #404040;
        }
      }
    }
  }
  .ant-layout-content {
    overflow: auto;
  }
}
.resouseLayout-menu {
  overflow: auto;
  // 隐藏滚动条
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
