body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

/* div { */
  /* user-select: none; */
  /* -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; */
/* } */
